import {
  LENGTH,
  CHOP,
  JOIN,
  RAIL,
  PHOTOFRAME,
  GALLERYFRAME,
  BOX,
} from "~/utils/products";
import { ThresholdCheckout } from "~/utils/threshold";
import { minWidthForSingleWedge } from "~/utils/builtFrames";
import { type ExpressStoreLine } from "../types";

export const lineValidation = (obj: ExpressStoreLine) => {
  if (resourceValidation(obj)) {
    return { Checkout: resourceValidation(obj) };
  }

  switch (obj.Type) {
    case LENGTH:
      return lengthValidation(obj);
    case CHOP:
      return chopValidation(obj);
    case JOIN:
      return joinValidation(obj);
    case RAIL:
      return railValidation(obj);
    case PHOTOFRAME:
      return photoFrameValidation(obj);
    case GALLERYFRAME:
      return galleryFrameValidation(obj);
    case BOX:
      return boxValidation(obj);
  }
};

type ErrorResult = {
  SKU?: string;
  LinearFt?: string;
  Quantity?: string;
  Cutting?: string;
  Width?: string;
  Height?: string;
  Length?: string;
  Layer?: string;
  Allowance?: string;
  Wedge?: string;
  Size?: string;
  NonDefaultPlant?: boolean;
  Checkout?: string;
  FreightCharge?: string;
  Orientation?: string;
  TariffCharge?: string;
};

const checkoutValidation = (obj: ExpressStoreLine) => {
  if (obj.Consents.CheckoutPermission) {
    if (
      obj.Consents.CheckoutPermission === ThresholdCheckout.backorder &&
      !obj.BackOrder
    ) {
      return "Consent required that this item will be placed on backorder until stock is replenished";
    } else if (
      obj.Consents.CheckoutPermission === ThresholdCheckout.optionalBackorder &&
      !obj.BackOrder &&
      !obj.CancelBackOrder
    ) {
      return "Choice required for low stock moulding";
    } else if (obj.Consents.CheckoutPermission === ThresholdCheckout.block) {
      return "This item cannot be fulfilled, please select another moulding or plant";
    } else {
      return undefined;
    }
  }
};

const resourceValidation = (obj: any) => {
  if (obj.plantDetails.latest?.status === "error") {
    if (obj.plantDetails.latest?.Error?.Code === "CREDIT_BLOCK") {
      return "Error retreiving product details because account is blocked. Please contact support for more details";
    } else {
      return "Error retreiving stock level information, please try again later or contact support for more assistance.";
    }
  }
};

const minMaxSize = (
  obj: ExpressStoreLine,
  size: number,
  label?: string,
  maximum?: number,
  minimum?: number
): string | undefined => {
  const max: number = maximum || 116;
  const mouldingWidth: number = obj.productData.latest?.Width ?? 0;
  const min: number = minimum || 4;

  if (mouldingWidth !== undefined) {
    if (obj.Layer === "I") {
      if (size + mouldingWidth * 2 > max) {
        return `Please enter a ${label ?? "size"} less than or equal to ${
          max - mouldingWidth * 2
        }`;
      } else if (size < min) {
        return `Please enter a ${
          label ?? "size"
        } greater than or equal to ${min}`;
      }
    } else {
      if (size > max) {
        return `Please enter a ${label ?? "size"} less than or equal to ${max}`;
      } else if (size - mouldingWidth * 2 < min) {
        return `Please enter a ${label ?? "size"} greater than or equal to ${
          min + mouldingWidth * 2
        }`;
      }
    }
  } else return;
};

const lengthValidation = (obj: ExpressStoreLine): ErrorResult => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU =
      "Moulding not available as selected order type, please select another moulding";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }

  if (!Number.isInteger(obj.Length)) {
    result.LinearFt = "Please enter a valid whole number";
  }

  if (!obj.Length) {
    result.LinearFt = "Please enter the amount of feet required for order";
  }

  if (obj.Length! <= 0) {
    result.LinearFt = "Linear feet amount must be greater than 0";
  }

  if (!obj.Cutting) {
    result.Cutting = "Please select a cutting instruction";
  }

  if (checkoutValidation(obj)) {
    result.Checkout = checkoutValidation(obj);
  }

  if (obj.Consents.FreightConsentRequired && !obj.FreightAccepted) {
    result.FreightCharge = "Consent required for additional freight charge";
  }

  if (obj.Consents.TariffConsentRequired && !obj.TariffAccepted) {
    result.TariffCharge = "Consent required for Tariff Fee"
  } 

  return result;
};

const chopValidation = (obj: ExpressStoreLine) => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU =
      "Moulding not available as selected order type, please select another moulding";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }
  if (!Number.isInteger(obj.Quantity)) {
    result.Quantity = "Please enter a valid whole number";
  }
  if (!obj.Quantity) {
    result.Quantity = "Please enter a quantity";
  }
  if (obj.Quantity < 1) {
    result.Quantity = "Quantity must be greater than 0";
  }
  if (!obj.Width) {
    result.Width = "Please enter a valid width";
  }
  if (obj.Width) {
    if (minMaxSize(obj, obj.Width)) {
      result.Width = minMaxSize(obj, obj.Width, "width");
    }
  }
  if (!obj.Height) {
    result.Height = "Please enter a valid height";
  }
  if (obj.Height) {
    if (minMaxSize(obj, obj.Height)) {
      result.Height = minMaxSize(obj, obj.Height, "height");
    }
  }
  if (!obj.Layer) {
    result.Layer = "Please select a layer";
  }
  if ((obj.Layer === "I" || !obj.Layer) && !obj.Allowance) {
    result.Allowance = "Please select an allowance";
  }
  if (checkoutValidation(obj)) {
    result.Checkout = checkoutValidation(obj);
  }
  if (obj.Consents.FreightConsentRequired && !obj.FreightAccepted) {
    result.FreightCharge = "Consent required for additional freight charge";
  }

  if (
    obj.productData?.latest?.Width! >= minWidthForSingleWedge &&
    !obj.productData?.latest?.Floater &&
    !obj.Wedge
  ) {
    result.Wedge = "Please select an option for wedge locks";
  }

  if (obj.isStretcher && !obj.Orientation) {
    result.Orientation = "Please select a stretcher orientation";
  }

  return result;
};

const joinValidation = (obj: ExpressStoreLine) => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU =
      "Moulding not available as selected order type, please select another moulding";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }
  if (!Number.isInteger(obj.Quantity)) {
    result.Quantity = "Please enter a valid whole number";
  }
  if (!obj.Quantity) {
    result.Quantity = "Please enter a quantity";
  }
  if (obj.Quantity < 1) {
    result.Quantity = "Quantity must be greater than 0";
  }
  if (!obj.Width) {
    result.Width = "Please enter a valid width";
  }
  if (obj.Width) {
    if (minMaxSize(obj, obj.Width)) {
      result.Width = minMaxSize(obj, obj.Width, "width");
    }
  }
  if (!obj.Height) {
    result.Height = "Please enter a valid Height";
  }
  if (obj.Height) {
    if (minMaxSize(obj, obj.Height)) {
      result.Height = minMaxSize(obj, obj.Height, "height");
    }
  }
  if (!obj.Layer) {
    result.Layer = "Please select a layer";
  }
  if ((obj.Layer === "I" || !obj.Layer) && !obj.Allowance) {
    result.Allowance = "Please select an allowance";
  }
  if (checkoutValidation(obj)) {
    result.Checkout = checkoutValidation(obj);
  }
  if (obj.Consents.FreightConsentRequired && !obj.FreightAccepted) {
    result.FreightCharge = "Consent required for additional freight charge";
  }

  if (obj.isStretcher && !obj.Orientation) {
    result.Orientation = "Please select a stretcher orientation";
  }

  return result;
};

const railValidation = (obj: ExpressStoreLine) => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU =
      "Moulding not available as selected order type, please select another moulding";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }
  if (!Number.isInteger(obj.Quantity)) {
    result.Quantity = "Please enter a valid whole number";
  }
  if (!obj.Quantity) {
    result.Quantity = "Please enter a quantity";
  }
  if (obj.Quantity < 1) {
    result.Quantity = "Quantity must be greater than 0";
  }
  if (!obj.Length) {
    result.Length = "Please enter a valid length";
  }
  if (obj.Length) {
    if (minMaxSize(obj, obj.Length)) {
      result.Length = minMaxSize(obj, obj.Length, "length");
    }
  }
  if (!obj.Layer) {
    result.Layer = "Please select a layer";
  }
  if (!obj.Allowance) {
    result.Allowance = "Please select an allowance";
  }
  if (checkoutValidation(obj)) {
    result.Checkout = checkoutValidation(obj);
  }
  if (obj.Consents.FreightConsentRequired && !obj.FreightAccepted) {
    result.FreightCharge = "Consent required for additional freight charge";
  }

  if (obj.isStretcher && !obj.Orientation) {
    result.Orientation = "Please select a stretcher orientation";
  }

  return result;
};

const photoFrameValidation = (obj: ExpressStoreLine) => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU = "Moulding not available as Photo Frame";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }
  if (!Number.isInteger(obj.Quantity)) {
    result.Quantity = "Please enter a valid whole number";
  }
  if (!obj.Quantity) {
    result.Quantity = "Please enter a quantity";
  }
  if (obj.Quantity < 2) {
    result.Quantity = "Minimum quantity of 2";
  }
  if (!obj.Size) {
    result.Size = "Please select a frame size";
  }

  return result;
};

const galleryFrameValidation = (obj: ExpressStoreLine) => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU = "Moulding not available as Gallery Frame";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }
  if (!Number.isInteger(obj.Quantity)) {
    result.Quantity = "Please enter a valid whole number";
  }
  if (!obj.Quantity) {
    result.Quantity = "Please enter a quantity";
  }
  if (obj.Quantity < 6) {
    result.Quantity = "Minimum quantity of 6";
  }
  if (!obj.Size) {
    result.Size = "Please select a frame size";
  }

  return result;
};

const boxValidation = (obj: ExpressStoreLine) => {
  let result: ErrorResult = {};

  if (!obj?.productData?.latest?.availableAsType) {
    result.SKU = "Moulding not available as Box";
  }
  if (!obj?.productData?.latest?.valid) {
    result.SKU = "Please enter a valid moulding number";
  }
  if (!Number.isInteger(obj.Quantity)) {
    result.Quantity = "Please enter a valid whole number";
  }
  if (!obj.Quantity) {
    result.Quantity = "Please enter a quantity";
  }
  if (obj.Quantity < 1) {
    result.Quantity = "Minimum quantity of 1";
  }
  if (checkoutValidation(obj)) {
    result.Checkout = checkoutValidation(obj);
  }
  if (obj.Consents.FreightConsentRequired && !obj.FreightAccepted) {
    result.FreightCharge = "Consent required for additional freight charge";
  }

  return result;
};
